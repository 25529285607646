import { createI18n } from "vue-i18n";
import EN from "./en.js";
import TR from "./tr.js";
import AL from "./al.js";
const messages = {
  en: { ...EN },
  tr: { ...TR },
  al: { ...AL },
};

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages,
});
export default i18n;
